<template>
  <section v-if="showMembership" id="pricing-plan">
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-2">
        {{ $t("Pages.Plan.Title.upgrade_account") }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t("Pages.Plan.Title.to_use_more_feature") }}
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6 class="mr-1 mb-0">
          {{ $t("Pages.Plan.Title.per_month") }}
        </h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="tooglePlan"
        />
        <h6 class="ml-50 mb-0">
          {{ $t("Pages.Plan.Title.per_year") }}
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->

    <b-modal
      id="modal-pricing"
      ref="modal-pricing"
      :title="$t('Pages.Plan.Title.payment_confirm')"
      ok-only
      centered
      :ok-title="$t('Pages.Plan.Button.Payment')"
      @ok="goToPaymentPage"
    >
      <b-card-text>
        <b-input-group>
          <b-form-input v-model="coupon" />
          <b-input-group-append>
            <b-button variant="primary" @click="validationCoupon">{{
              $t("Pages.Plan.Button.regis_discount")
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <p class="mt-2" v-if="price_discount_status">
          <span v-if="price_discount > 0">
            {{
              $t("Pages.Plan.Title.amount_payable : {price}", {
                price: price_discount.toLocaleString(),
              })
            }}
          </span>
          <span v-else>
            {{
              $t("Pages.Plan.Title.amount_payable : {price}", {
                price: $t("Pages.Plan.Title.Free"),
              })
            }}
          </span>
        </p>
      </b-card-text>
    </b-modal>

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="12" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row class="d-flex justify-content-center match-height">
          <template v-for="plan in plans">
            <b-col md="3" :key="plan.id" v-if="plan.id == 1 || plan.id == 3">
              <b-card class="popular" align="center">
                <!-- img -->
                <b-img :src="plan.icon" class="mb-1" width="70" alt="svg img" />
                <!--/ img -->
                <h3>{{ plan.name }}</h3>
                <b-card-text class="text-center">{{
                  plan.description
                }}</b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup
                      v-if="monthlyPlanShow"
                      class="font-medium-1 font-weight-bold text-primary"
                      >{{ $t("Pages.Plan.Title.per_month") }}</sup
                    >
                    <sup
                      v-if="!monthlyPlanShow"
                      class="font-medium-1 font-weight-bold text-primary"
                      >{{ $t("Pages.Plan.Title.per_year") }}</sup
                    >
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      >{{
                        monthlyPlanShow
                          ? plan.price.toLocaleString()
                          : (plan.price * 10).toLocaleString()
                      }}</span
                    >
                    <sub
                      class="pricing-duration text-body font-medium-1 font-weight-bold"
                      >{{ plan.currency}}</sub
                    >
                  </div>

                  <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                    >{{ $t("Pages.Plan.Title.per_year") }}
                    {{ (plan.price * 12).toLocaleString() }}
                    {{ $t("Pages.Plan.Title.Tooman") }}</small
                  >
                </div>
                <p class="text-muted" v-if="plan.price != 0">
                  {{ $t("Pages.Plan.Title.get_twenty_discount") }}
                </p>
                <p class="text-muted mt-5" v-else></p>

                <!--/ annual plan -->

                <b-list-group  class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data, index) in plan.group"
                    :key="index"
                  >
                    <del v-if="!data.status">{{ data.name }}</del>
                    <span class="text-info" v-if="data.status">{{
                      data.name
                    }}</span>
                  </b-list-group-item>
                </b-list-group>

                <!-- buttons -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="outline-primary"
                >
                  <span v-if="plan.price == 0">{{
                    $t("Pages.Plan.Button.free_account")
                  }}</span>
                  <span
                    v-else-if="plan.id == membership.id"
                    @click="pay(plan.id)"
                    >{{ $t("Pages.Plan.Button.account_renewal") }}</span
                  >
                  <span
                    v-else-if="plan.id != membership.id"
                    @click="pay(plan.id)"
                    >{{ $t("Pages.Plan.Button.upgrade_account") }}</span
                  >
                </b-button>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>

    <b-card
      class="d-flex flex-column justify-content-center align-items-center bg-darken"
    >
      <h1 class="text-primary pt-3 pb-2 text-center">
        {{ $t("Pages.Plan.Title.better_decision") }}
      </h1>
      <b-col offset-sm-4 sm="12" md="12" offset-lg="4" lg="8" class="mx-auto">
        <b-row>
          <table
            class="table my-1"
            v-for="(data, index) in plans[0].group"
            :key="index"
          >
            <thead>
              <tr>
                <th style="width: 50%">{{ data.name }}</th>
                <th v-if="plans[0]">{{ plans[0].name }}</th>
                <!-- <th v-if="plans[1]">{{plans[1].name_fa}}</th> -->
                <th v-if="plans[2]">{{ plans[2].name }}</th>
                <!-- <th v-if="plans[3]">{{plans[3].name_fa}}</th>
                  <th v-if="plans[4]">{{plans[4].name_fa}}</th> -->
              </tr>
            </thead>
            <tbody>
              <!-- ////group-items///// -->
              <tr v-for="(item, index1) in data.items" :key="index1">
                <th style="width: 50%">{{ item.title }}</th>
                <td v-if="plans[0]">
                  <feather-icon
                    v-if="plans[0].group[index].items[index1].status"
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                    size="16"
                    class="text-primary"
                  />
                  <span
                    v-if="plans[0].group[index].items[index1].count_coin > 0"
                    >{{ plans[0].group[index].items[index1].count_coin }}</span
                  ><span
                    v-if="plans[0].group[index].items[index1].count_coin > 0"
                    >*</span
                  >
                  <b-img
                    v-if="plans[0].group[index].items[index1].count_coin > 0"
                    class=""
                    :src="require('@/assets/images/icons/mastercoin_icon.png')"
                    width="24"
                  />
                  <span v-if="plans[0].group[index].items[index1].meta">{{
                    plans[0].group[index].items[index1].meta.count
                  }}</span>
                </td>
                <td v-if="plans[2]">
                  <feather-icon
                    v-if="plans[2].group[index].items[index1].status"
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                    size="16"
                    class="text-primary"
                  />
                  <span
                    v-if="plans[2].group[index].items[index1].count_coin > 0"
                    >{{ plans[2].group[index].items[index1].count_coin }}</span
                  ><span
                    v-if="plans[2].group[index].items[index1].count_coin > 0"
                    >*</span
                  >
                  <b-img
                    v-if="plans[2].group[index].items[index1].count_coin > 0"
                    class=""
                    :src="require('@/assets/images/icons/mastercoin_icon.png')"
                    width="24"
                  />
                  <span v-if="plans[2].group[index].items[index1].meta">{{
                    plans[2].group[index].items[index1].meta.count
                  }}</span>
                </td>
               
              </tr>
            </tbody>
          </table>
        </b-row>
     
      </b-col>
    </b-card>

    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t("Pages.Plan.Title.still_not_sure") }}
              </h3>
              <h5>{{ $t("Pages.Plan.Title.get_silver_sub") }}</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                @click="requestTrialPlan"
              >
                {{ $t("Pages.Plan.Button.active_gift") }}
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <div class="pricing-faq">
      <h2 class="text-center text-primary">
        {{ $t("Pages.Plan.Title.Faq") }}
      </h2>
      <h5 class="text-center">
        {{ $t("Pages.Plan.Title.lets_answer") }}
      </h5>
      <b-row class="py-2">
        <b-col lg="10" offset-lg="2" class="mx-auto">
          <app-collapse accordion type="margin">
            <app-collapse-item
              v-for="(data, index) in faq"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BModal,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import consoleService from "@/services/consoleService";

/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: "monthly",
      monthlyPlanShow: true,
      membership: {},
      showMembership: false,
      plans: [{group:[]}],
      id: 2,
      price_discount: null,
      price_discount_status: false,
      coupon: "",
      faq: [
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q1"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans1"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q2"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans2"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q3"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans3"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q4"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans4"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q5"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans5"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q6"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans6"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q7"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans7"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q8"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans8"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q9"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans9"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q10"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans10"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q11"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans11"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q12"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans12"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q13"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans13"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q14"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans14"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q15"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans15"),
        },
        {
          question: this.$t("Pages.Plan.Title.membership_faq_q16"),
          ans: this.$t("Pages.Plan.Title.membership_faq_ans16"),
        },
      ],
    };
  },
  created() {
    this.getListPlan();
    this.getUserMembership();
  },
  methods: {
    tooglePlan() {
      if (this.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
    getUserMembership() {
      this.$http.get("/api/dashboard/getUserPlan").then((response) => {
        this.membership = response.data;
        this.showMembership = true;
      });
    },
    validationCoupon() {
      this.$http
        .post("/api/coupon/check/plan", {
          monthly: this.monthlyPlanShow,
          id: this.id,
          code: this.coupon,
        })
        .then(({ data }) => {
          if (data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.Plan.Alert.discount_applied_success"),
              "success"
            );
            this.price_discount = data.price;
            this.price_discount_status = true;
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Pages.Plan.Alert.discount_invalid"),
              "danger"
            );
          }
        })
        .catch(() => {
          this.price_discount = null;
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Plan.Alert.discount_invalid"),
            "danger"
          );
        });
    },
    pay(id) {
      this.$refs["modal-pricing"].show();
      this.id = id;
    },
    goToPaymentPage() {
      this.makeToast(
        this.$t("Base.Alert.please_wait"),
        this.$t("Pages.Plan.Alert.transfer_portal"),
        "success"
      );
      this.$http
        .post(`/api/plan/pay`, {
          monthly: this.monthlyPlanShow,
          id: this.id,
          coupon: this.coupon,
        })
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Plan.Alert.pay_gateway"),
            "danger"
          );
        });
    },
    async getListPlan() {
      try {
        const response = await this.$http.get("/api/plan/items");
        this.plans = response.data.result;
      } catch (error) {
        consoleService.error("Error while fetching plans:", error);
      }
    },
    requestTrialPlan() {
      this.$http.post("/api/plan/trial/request").then((response) => {
        if (response.data.status == true || response.data.status == "true") {
          this.makeToast(
            this.$t("Base.Alert.Successful"),
            this.$t("Pages.Plan.Alert.free_plan_activated"),
            "success"
          );
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Plan.Alert.already_used_plan"),
            "warning"
          );
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
